<template>
  <div class="medicine_container" :style="`background: ${pageData.bgcolor}`">
    <div v-if="pageData.banner" class="img">
      <img :src="pageData.banner" alt="" />
    </div>
    <div class="content">
      <!-- <div v-for="item in list" :key="item.id" :id="item.id"> -->
      <!-- <div class="title">
            <img style="width: 42px; height: 25px" src="@/assets/img/zone/zuo.png" alt="" />
            <p>{{ item.title }}</p>
            <img style="width: 42px; height: 25px" src="@/assets/img/zone/you.png" alt="" />
            <div class="more" @click="handleMore(item)">更多 <i class="el-icon-arrow-right"></i></div>
          </div> -->
      <div class="cate-type">
        <div v-for="(item, index) in pageData.cate" :key="index" @click="chooseItem(item, index)" :class="['cate-item', activeInd == index ? 'active-color' : '']">
          <img :src="item.logo" class="cate-img" alt="" />
          <!-- <div>{{ item.title }}</div> -->
        </div>
      </div>
      <!-- pageData.activityGoods -->
      <div class="active-box" v-if="pageData.activityGoods && pageData.activityGoods.length > 0">
        <div class="active-title">活动品种</div>
      </div>
      <div class="item_wrap" v-if="activityGoods.length > 0">
        <div class="item" v-for="sub in activityGoods" :key="sub.id">
          <bigimg :itemData="sub" />
        </div>
      </div>
      <div class="goods-title">
        <div class="active-title">为您推荐</div>
        <div class="cate-title">
          <div>{{ typeTitle }}</div>
          <div class="serach_box">
            <el-input placeholder="请输入搜索内容 名称/厂家" v-model="kw" @keyup.enter.native="handleEnter" @input="handleInput" class="input-w"> </el-input>
            <!-- <div class="box" v-if="listShow">
              <div v-for="item in arr" :key="item.value" @click="chooseItem(item)">
                {{ item.title }}
              </div>
            </div> -->
            <div class="search_str" @click="handleClick">搜索</div>
          </div>
        </div>
      </div>
      <div class="item_wrap" v-if="list.length > 0">
        <div class="item" v-for="sub in list" :key="sub.id">
          <bigimg :itemData="sub" />
        </div>
        <pagination :total="total" :currentPage="page" :pageSize="page_size" :pageSizes="[20, 40, 80, 120, 200]" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
      </div>
      <div class="empty">
        <el-empty v-if="!list.length" :image-size="200"></el-empty>
      </div>
      <!-- </div> -->
    </div>

    <!--左侧导航  -->
    <!-- <floornav :list="list" /> -->
  </div>
</template>

<script>
import bigimg from "@/components/goodsItem/bigImg.vue";
// import floornav from "@/components/floornav.vue";
import { handleJump, getZoneInfo } from "@/utils";
import pagination from "@/components/pagination.vue";
export default {
  components: {
    // item,
    bigimg,
    pagination,
    // floornav,
  },
  data() {
    return {
      handleJump,
      pageData: {},
      activityGoods: [],
      list: [],
      info: {},
      typeTitle: "",
      activeInd: -1,
      zone_id: "",
      kw: "",
      page: 1,
      page_size: 20,
      total: 0,
      //   form: {
      //   zone_id: "",
      //   },
    };
  },
  watch: {
    $route(to) {
      this.zone_id = to.query.zone_id;
      this.getData();
      this.getGoodsList();
    },
  },
  created() {
    // console.log("地址栏参数-----", this.$route.query.zone_id);
    if (this.$route.query.zone_id) {
      this.zone_id = this.$route.query.zone_id;
      this.getData();
      this.getGoodsList();
    }
    getZoneInfo(this.$route.query.value).then((res) => {
      this.info = res;
    });
  },
  methods: {
    getGoodsList() {
      this.$api("goods.getGoods", { zone_id: this.zone_id, page: this.page, page_size: this.page_size, kw: this.kw, cate_id: this.cate_id }).then((res) => {
        // this.list = res.data.list;
        this.list = res.data.list.map((v) => {
          this.$set(v, "number", v.min_num);
          return v;
        });
        this.total = res.data.count;
      });
    },
    getData() {
      this.$api("zone.getZoneData", { zone_id: this.zone_id }).then((res) => {
        this.pageData = res.data;
        this.activityGoods = this.pageData.activityGoods.map((item) => {
          this.$set(item, "number", item.min_num);
          return item;
        });
        // console.log("测试数据-----", this.pageData);
      });
    },
    chooseItem(data, ind) {
      // console.log("666-----------", data);
      this.activeInd = ind;
      this.typeTitle = data.title;
      // this.zone_id = data.id;
      this.cate_id = data.id;
      this.getGoodsList();
    },
    handleMore(item) {
      //   console.log("中药专区----", item);
      let firstTab = this.$store.state.pageName;
      //   console.log("面包屑---", firstTab);
      this.$store.commit("CHANGE_PAGENAME", [firstTab[0], item.title]);
      if (item.url.url == "/index/allGoods") {
        this.$store.commit("CHANGE_TAB", 1);
      }
      handleJump(item.url);
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoodsList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getGoodsList();
    },
    handleClick() {
      this.page = 1;
      this.getGoodsList();
    },
    handleEnter() {
      this.page = 1;
      this.getGoodsList();
    },
    handleInput() {},
  },
};
</script>

<style lang="less" scoped>
.medicine_container {
  background-size: cover;
  background: #f8f7f1;
  padding-bottom: 20px;
}
.img {
  width: 100%;
  height: 375px;
}
.title {
  width: 100%;
  padding: 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 26px;
  font-weight: bold;
  color: #5a151a;
  position: relative;
  p {
    margin: 0 15px;
  }
  .more {
    position: absolute;
    right: 0;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
  }
}
.item_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
  .item {
    margin-right: 12px;
  }
  .item:nth-child(5n) {
    margin-right: 0;
  }
}
.cate-type {
  width: 100%;
  //   height: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  //   background-color: aquamarine;
}
.cate-item {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  text-align: center;
  .cate-img {
    width: 100px !important;
    height: 100px !important;
  }
}
.cate-item:hover {
  color: #0abc64;
  transform: scale(1.1); /* 放大10% */
}
.active-color {
  color: #0abc64;
}
.active-box {
  width: 100%;
  margin: 20px 0;
  margin-top: 50px;
  padding-bottom: 20px;
  border-bottom: 2px solid #ff9800;
  .active-title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #333;
    // margin-top: 50px;
    // color: #a65817;
  }
}
.goods-title {
  width: 100%;
  margin: 20px 0;
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 2px solid #ff9800;
  .active-title {
    width: 100%;
    text-align: center;
    font-size: 30px;
    color: #333;
    // color: #a65817;
  }
  .cate-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    .serach_box {
      height: 34px;
      display: flex;
      .input-w {
        width: 210px;
        height: 34px;
        border: 1px solid @themeColor;
        ::v-deep .el-input__inner {
          width: 210px;
          height: 34px;
          border: none;
        }
      }
      .box {
        z-index: 1000;
        position: absolute;
        top: 60px;
        background: #fff;
        width: 190px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        padding: 0 10px;
        max-height: 450px;
        overflow-y: auto;
        line-height: 1.6;
        div {
          cursor: pointer;
          &:hover {
            color: @themeColor;
          }
        }
      }
      .search_str {
        cursor: pointer;
        width: 40px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: @themeColor;
        font-size: 12px;
        color: #ffffff;
        border: 1px solid @themeColor;
      }
    }
  }
}
</style>
